import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import Link from "lib/Link";
import withLocation from "lib/withLocation";
import ReactHtmlParser from "react-html-parser";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class HotRankingsBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
    };
  }

  async componentDidMount() {}

  @autobind
  loadDefaultImg(e) {
    e.target.src = "/file/images/featured_pic02.jpg";
  }

  @autobind
  changeType(type) {
    this.setState({
      type: type,
    });
  }

  render() {
    let { t, appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { hotRankingsBlock } = indexdata;
    if (hotRankingsBlock === null || hotRankingsBlock === undefined) {
      return null;
    }
    let { domainList } = hotRankingsBlock;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let hotRankingsDomain = {
      domain: "default",
      mainHotRankings: null,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          hotRankingsDomain = m;
        }
        return "";
      });
    }
    let { mainHotRankings = [] } = hotRankingsDomain;
    if (!mainHotRankings || mainHotRankings.length === 0) {
      return null;
    }
    if (this.state.type === "") {
      this.changeType(mainHotRankings[0].type);
    }
    let moreUrl = "";
    return (
      <>
        <section className="section col-12 hotrankings_block">
          <div className="container">
            <div className="col">
              <div className="hotrankings">
                {hotRankingsDomain.mainHotRankings.map((main) => {
                  return (
                    <div className="sort">
                      <a
                        onClick={this.changeType.bind(this, main.type)}
                        className={main.type === this.state.type ? "here" : ""}>
                        {ReactHtmlParser(t(main.mainLangcode))}
                      </a>
                    </div>
                  );
                })}
              </div>
              <div className="hotbook_column">
                {mainHotRankings.map((main) => {
                  if (main.type === this.state.type) {
                    let out = [];
                    if (main.mappingData) {
                      main.mappingData.map((row) => {
                        let out2 = [];
                        if (
                          row.data &&
                          row.condition ===
                            session.groupIDVal + "-" + session.useUnitGroupSN
                        ) {
                          moreUrl = row.moreUrl;
                          row.data.map((row2, k) => {
                            out2.push(
                              <div className="hotbooklist">
                                <div className="image">
                                  <Link href={row2.url}>
                                    <img
                                      src={row2.imagePath}
                                      alt={t("jumperrwd.common.book")}
                                      onError={this.loadDefaultImg}
                                    />
                                  </Link>
                                </div>
                                <div className="clickblock"></div>
                                <h3>
                                  {main.showRanking && (
                                    <span className="ranking">#{k + 1}</span>
                                  )}
                                  <Link href={row2.url}>
                                    {ReactHtmlParser(row2.name)}
                                  </Link>
                                </h3>
                              </div>
                            );
                            return "";
                          });
                        }
                        out.push(out2);
                        return "";
                      });
                    }
                    return out;
                  }
                  return "";
                })}
              </div>
              {moreUrl !== "" && (
                <div className="more">
                  <Link href={moreUrl} className="btnstyle brownbg">
                    {t("jumperrwd.hotRankingsBlock.moreData")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

HotRankingsBlock.defaultProps = {};

HotRankingsBlock.propTypes = {
  t: PropTypes.func,
};

export default HotRankingsBlock;
