import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import Link from "lib/Link";
import withLocation from "lib/withLocation";
import ReactHtmlParser from "react-html-parser";

@withLocation
@withI18next(["common"])
@inject("appStore", "readerStore")
@observer
class HotWeb20Block extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
    };
  }

  async componentDidMount() {}

  @autobind
  loadDefaultImg(e) {
    e.target.src = "/file/images/featured_pic02.jpg";
  }

  @autobind
  changeType(type) {
    this.setState({
      type: type,
    });
  }

  render() {
    let { t, appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { hotWeb20Block } = indexdata;
    if (hotWeb20Block === null || hotWeb20Block === undefined) {
      return null;
    }
    let { domainList } = hotWeb20Block;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let hotWeb20Domain = {
      domain: "default",
      mainHotWeb20: null,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          hotWeb20Domain = m;
        }
        return "";
      });
    }
    let { mainHotWeb20 = [] } = hotWeb20Domain;
    if (!mainHotWeb20 || mainHotWeb20.length === 0) {
      return null;
    }
    if (this.state.type === "") {
      this.changeType(mainHotWeb20[0].type);
    }
    let moreUrl = "";
    return (
      <>
        <div className="hotboard">
          <section className="section col-12 hotweb20_block">
            <div className="container">
              <div className="col">
                <div className="hotrankings">
                  {hotWeb20Domain.mainHotWeb20.map((main) => {
                    return (
                      <div className="sort">
                        <a
                          onClick={this.changeType.bind(this, main.type)}
                          className={
                            main.type === this.state.type ? "here" : ""
                          }>
                          {ReactHtmlParser(t(main.mainLangcode))}
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div className="hotbook_column">
                  {mainHotWeb20.map((main) => {
                    if (main.type === this.state.type) {
                      let out = [];
                      if (main.mappingData) {
                        main.mappingData.map((row) => {
                          let out2 = [];
                          if (
                            row.data &&
                            row.condition ===
                              session.groupIDVal + "-" + session.useUnitGroupSN
                          ) {
                            moreUrl = row.moreUrl;
                            let out3 = [];
                            let blockPostion = 1;
                            row.data.map((row2, k) => {
                              if (k === 0) {
                                out3.push(
                                  <>
                                    <div className="hotbook_title">
                                      <h2>
                                        {ReactHtmlParser(t(main.mainLangcode))}
                                      </h2>
                                      <div className="image">
                                        <img
                                          src={main.imagePath}
                                          alt={ReactHtmlParser(
                                            t(main.mainLangcode)
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="bookno01">
                                      <div className="clickblock">
                                        <div className="ranking">
                                          {main.showPoundSign && <>#</>}
                                          {k + 1}
                                        </div>
                                        {main.showCount && (
                                          <div className="clicktimes">
                                            {t("jumperrwd.hotWeb20.timesShow", {
                                              times: row2.count,
                                            })}
                                          </div>
                                        )}
                                      </div>
                                      <div className="image">
                                        <img
                                          src={row2.imagePath}
                                          alt={t("jumperrwd.common.book")}
                                          onError={this.loadDefaultImg}
                                        />
                                      </div>
                                      <h3>
                                        <Link href={row2.url}>
                                          {ReactHtmlParser(row2.name)}
                                        </Link>
                                      </h3>
                                    </div>
                                  </>
                                );
                              } else {
                                out3.push(
                                  <div className="hotbooklist">
                                    <div className="image">
                                      <img
                                        src={row2.imagePath}
                                        alt={t("jumperrwd.common.book")}
                                        onError={this.loadDefaultImg}
                                      />
                                    </div>
                                    <div className="clickblock">
                                      <div className="ranking">
                                        {main.showPoundSign && <>#</>}
                                        {k + 1}
                                      </div>
                                      {main.showCount && (
                                        <div className="clicktimes">
                                          {t("jumperrwd.hotWeb20.timesShow", {
                                            times: row2.count,
                                          })}
                                        </div>
                                      )}
                                    </div>
                                    <h3>
                                      <Link href={row2.url}>
                                        {ReactHtmlParser(row2.name)}
                                      </Link>
                                    </h3>
                                  </div>
                                );
                              }
                              if (k % 2 === 0) {
                                out2.push(
                                  <div
                                    className={"hotbook_block0" + blockPostion}>
                                    {out3}
                                  </div>
                                );
                                out3 = [];
                                blockPostion++;
                              }
                              return "";
                            });
                          }
                          out.push(out2);
                          return "";
                        });
                      }
                      return out;
                    }
                    return "";
                  })}
                </div>
                {moreUrl !== "" && (
                  <div className="more">
                    <Link href={moreUrl} className="btnstyle brownbg">
                      {t("jumperrwd.hotWeb20Block.moreData")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

HotWeb20Block.defaultProps = {};

HotWeb20Block.propTypes = {
  t: PropTypes.func,
};

export default HotWeb20Block;
